import { ThemeOptions } from '@material-ui/core/styles';

const textField = (): ThemeOptions['overrides'] => ({
  MuiInput: {
    root: {
      'label + &': {
        marginTop: 6,
      },
    },
  },
});

export default textField;

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Dialog,
  DialogProps,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/ArrowBack';
import LikedIcon from '@material-ui/icons/Favorite';
import NotLikedIcon from '@material-ui/icons/FavoriteBorder';
import DownloadIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/styles';
import useOnlineStatus from '@rehooks/online-status';

import PDFViewer from '../../components/PDFViewer';
import { Fiche } from '../../context/dataContext';
import { useLikes } from '../../context/likesContext';

const useStyles = makeStyles(() => {
  return {
    appBar: {
      position: 'relative',
    },
    title: {
      flex: 1,
    },
    icon: {
      color: 'inherit',
    },
    wrapper: {
      width: '100%',
      height: '100%',
      margin: 0,
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
  };
});

export interface PDFDialogProps {
  fiche: Fiche;
}

const PDFDialog: React.FC<PDFDialogProps & DialogProps> = ({
  fiche,
  onClose,
  ...other
}) => {
  const classes = useStyles();
  const [{ isLiked }, { like }] = useLikes();
  const { id } = fiche;
  const pdfSrc = `${process.env.REACT_APP_DATA_DOMAIN}${fiche.pdfUrl}`;

  const [isError, setIsError] = useState(false);
  const [showPage, setShowPage] = useState(true);

  const isOnline = useOnlineStatus();

  useEffect(() => {
    if (isError && isOnline) {
      setShowPage(false);
      setTimeout(() => setShowPage(true), 10);
    }
  }, [isError, isOnline]);

  return (
    <Dialog
      fullScreen
      aria-labelledby='simple-dialog-title'
      onClose={onClose}
      {...other}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.icon}
            onClick={e => onClose && onClose(e, 'backdropClick')}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>{fiche.title}</Typography>
          <IconButton
            className={classes.icon}
            onClick={() => pdfSrc && window.open(pdfSrc, '__blank')}
          >
            <DownloadIcon />
          </IconButton>
          <IconButton
            className={classes.icon}
            onClick={() => like(id, !isLiked(id))}
          >
            {isLiked(id) ? <LikedIcon /> : <NotLikedIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>

      <div className={classes.wrapper}>
        {showPage && (
          <PDFViewer fiche={fiche} pdfSrc={pdfSrc} setIsError={setIsError} />
        )}
      </div>
    </Dialog>
  );
};

export default PDFDialog;

import { ReactElement, ReactNode } from 'react';
import { Grid, GridSize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles';
import { ElementWidth } from '@solar/solar-react';
import clsx from 'clsx';

export interface CardListProps<T> {
  upMap: unknown;
  items: (T & { id: string })[];
  children: (item: T & { id: string }) => ReactNode;
  classes: ClassNameMap;
}

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: 'auto',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(1600 + theme.spacing(3) * 2)]: {
        // width: 1600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    cardGrid: {
      // padding: `${theme.spacing(8)}px 0`,
    },
    gridContainer: {
      width: '100%',
      margin: 0,
    },
    gridItem: {},
    singleColumn: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
    twoColumns: {
      flexGrow: 0,
      maxWidth: '50%',
      flexBasis: '50%',
    },
  };
});

const CardList = <T extends unknown>({
  upMap,
  children,
  items,
  classes: eClasses,
}: CardListProps<T>): ReactElement => {
  const classes = useStyles({ classes: eClasses });

  return (
    <ElementWidth upMap={upMap}>
      {({ cols, width }: { cols: number; width: number }) => {
        if (!width) {
          return '';
        }
        return (
          <div className={clsx(classes.root, classes.cardGrid)}>
            <Grid
              container
              spacing={5}
              classes={{ container: classes.gridContainer }}
            >
              {items.map(item => {
                return (
                  <Grid
                    item
                    key={item.id}
                    sm={(12 / cols) as GridSize}
                    className={clsx(classes.gridItem, {
                      [classes.singleColumn]: cols === 1,
                      [classes.twoColumns]: cols === 2,
                    })}
                  >
                    {children(item)}
                  </Grid>
                );
              })}
            </Grid>
          </div>
        );
      }}
    </ElementWidth>
  );
};

/* eslint-disable react/default-props-match-prop-types */
CardList.defaultProps = {
  upMap: {
    0: { cols: 1 },
    600: { cols: 2 },
    1000: { cols: 3 },
    1400: { cols: 4 },
  },
};
/* eslint-enable react/default-props-match-prop-types */

export default CardList;

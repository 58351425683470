import { Context, createContext, FC, useContext, useMemo } from 'react';

import useCachedHtmlParse from '../hooks/useCachedHtmlParse';

export type Fiche = {
  id: string;
  pdfUrl?: string;
  title: string;
  description: string;
  lastUpdatedAt: string;
  categoryTitle: string;
  categoryLabel: string;
  categoryId: string;
};

export type Category = {
  categoryId: string;
  label: string;
  title: string;
  fiches: Fiche[];
  description?: string;
};

export type Categories = {
  [key: string]: Category;
};

type ExtraCategoryLabels = {
  [key: string]: string;
};

const extraCategoryLabels: ExtraCategoryLabels = {
  'taxonomy-term-58': 'PV',
  'taxonomy-term-73': 'PF',
};

const parseFicheInCategory = (
  ficheElement: Element,
  categoryTitle: string,
  categoryLabel: string,
  categoryId: string,
): Fiche | undefined => {
  const ficheLinkElement =
    ficheElement.getElementsByTagName('a')[0] || ficheElement;

  const completeTitle =
    ficheLinkElement?.getElementsByClassName('field--name-title')[0]?.innerHTML;

  if (!completeTitle.includes('Steekkaart')) {
    return undefined;
  }

  const titleString = completeTitle.split('Steekkaart ').pop();
  const title = (titleString &&
    titleString?.charAt(0).toUpperCase() + titleString?.slice(1)) as string;

  const id = ficheElement.getAttribute('about') || '';

  const pdfUrl = ficheLinkElement?.getAttribute('href') || undefined;

  const lastUpdatedAt = ficheLinkElement
    ?.getElementsByClassName('field__date--changed')[0]
    ?.innerHTML.split('</span>')[1];

  const description = ficheLinkElement
    ?.getElementsByClassName('field--name-field-teaser')[0]
    ?.getElementsByTagName('p')[0]?.innerHTML;

  return {
    id,
    pdfUrl,
    title,
    lastUpdatedAt,
    description,
    categoryTitle,
    categoryLabel,
    categoryId,
  };
};

const parseCategory = (categoryElement: Element): Category => {
  const categoryId = categoryElement.getElementsByClassName(
    'vocabulary-material-categories',
  )[0]?.id;
  const label =
    (
      categoryElement.getElementsByClassName(
        'field--name-field-letter',
      )[0] as HTMLElement
    )?.innerText || extraCategoryLabels[categoryId];
  const title = (
    categoryElement.getElementsByClassName('field--name-name')[0] as HTMLElement
  )?.innerText;

  const ficheElements = categoryElement.getElementsByClassName(
    'node--type-material',
  );
  const fiches = Array.from(ficheElements)
    .map(ficheElement =>
      parseFicheInCategory(ficheElement, title, label, categoryId),
    )
    .filter(fiche => fiche !== undefined) as Fiche[];

  return { categoryId, label, title, fiches };
};

const documentParse = (html: Document) => {
  const categoryElements = html.getElementsByClassName('material__category');

  const categoriesArray: Category[] =
    Array.from(categoryElements).map(parseCategory);

  return categoriesArray;
};

export type DataContextType = {
  categories: Categories | undefined;
  categoriesArray: Category[] | undefined;
  allFiches: Fiche[] | undefined;
  isLoading: boolean;
  error: unknown;
};

export const DataContext: Context<DataContextType | undefined> = createContext<
  DataContextType | undefined
>(undefined);

export const DataProvider: FC = ({ children }) => {
  const {
    data: categoriesArray,
    isLoading,
    error,
  } = useCachedHtmlParse<Category[]>({
    url: process.env.REACT_APP_DATA_GUIDELINES_URL as string,
    documentParse,
    cacheTime: parseInt(process.env.REACT_APP_CACHE_TIME || '86400000', 10),
  });

  const categories: Categories | undefined = useMemo(
    () =>
      categoriesArray?.reduce(
        (prev, current) => ({
          ...prev,
          [current.categoryId]: current,
        }),
        {},
      ),
    [categoriesArray],
  );

  const allFiches = useMemo(
    () =>
      categoriesArray?.reduce(
        (prev, current) => [...prev, ...current.fiches],
        [] as Fiche[],
      ),
    [categoriesArray],
  );

  return (
    <DataContext.Provider
      value={{ categories, categoriesArray, allFiches, isLoading, error }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useData must be used within a DataProvider');
  }

  return context;
};

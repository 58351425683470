import { FC } from 'react';
import { Box, Dialog, makeStyles } from '@material-ui/core';

import CategoryMenu from '../../components/CategoryMenu';
import { LogoHeader } from '../../components/LogoHeader';
import { Category } from '../../context/dataContext';

export interface CategoryOverviewDialogProps {
  isOpen: boolean;
  categories: Category[];
  onAllClick: () => void;
  onFavoritesClick: () => void;
  onCategoryClick: (category: string) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const CategoryOverviewDialog: FC<CategoryOverviewDialogProps> = ({
  isOpen,
  categories,
  onAllClick,
  onFavoritesClick,
  onCategoryClick,
}) => {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={isOpen}>
      <Box className={classes.root}>
        <LogoHeader />
        <CategoryMenu
          categories={categories}
          onAllClick={onAllClick}
          onFavoritesClick={onFavoritesClick}
          onCategoryClick={onCategoryClick}
          widthFull
        />
      </Box>
    </Dialog>
  );
};

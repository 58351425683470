import { useMemo } from 'react';
import { pdfjs } from 'react-pdf';
import { Route, Switch, useHistory } from 'react-router-dom';
import { IconButton, isWidthDown, Typography } from '@material-ui/core';
import CardIcon from '@material-ui/icons/Dashboard';
import ListIcon from '@material-ui/icons/List';
import { useWidth } from '@solar/solar-react';

import CategoryMenu from './components/CategoryMenu';
import { Category, useData } from './context/dataContext';
import { useUI } from './context/uiContext';
import CategoryOverview from './screens/CategoryOverview/CategoryOverview';
import FicheView from './screens/FicheView/FicheView';
import Loader from './screens/Loader/Loader';
import OfflineScreen from './screens/Offline/OfflineScreen';
import Pdf from './screens/PDF/Pdf';
import { App as SolarApp } from './solar/App';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function App() {
  const [{ view }, { setDrawerOpen, setView }] = useUI();
  const history = useHistory();
  const width = useWidth();
  const isPhone = isWidthDown('xs', width);

  const { categoriesArray, error } = useData();

  // add amount of fiches to categories
  const expandedCategories: Category[] | undefined = useMemo(() => {
    return categoriesArray?.map(({ fiches, ...other }) => ({
      fiches,
      description: `${fiches.length} steekkaarten`,
      ...other,
    }));
  }, [categoriesArray]);

  const closeDrawer = () => {
    // only on phone: autoclose. When docked don't close
    if (isPhone) {
      setDrawerOpen(false);
    }
  };

  const onAllClick = () => {
    closeDrawer();
    history.push(`/`);
  };

  const onFavoritesClick = () => {
    closeDrawer();
    history.push(`/favorites`);
  };

  const onCategoryClick = (category: string) => {
    closeDrawer();
    history.push(`/${category}`);
  };

  if (!expandedCategories && error) {
    return <OfflineScreen />;
  }

  if (!expandedCategories) {
    return <Loader hasLogo />;
  }

  return (
    <SolarApp
      drawerContent={
        <CategoryMenu
          categories={expandedCategories}
          onAllClick={onAllClick}
          onFavoritesClick={onFavoritesClick}
          onCategoryClick={onCategoryClick}
          showActiveCategory
        />
      }
      toolbarContent={
        <>
          <Typography
            noWrap
            variant='h5'
            style={{
              flexGrow: 1,
              fontWeight: 800,
              lineHeight: '2em',
              paddingTop: 5,
            }}
          >
            Domus Medica Steekkaarten
          </Typography>
          <IconButton
            onClick={() => setView(view === 'list' ? 'card' : 'list')}
            color='inherit'
          >
            {view === 'list' ? <CardIcon /> : <ListIcon />}
          </IconButton>
        </>
      }
    >
      <>
        <Switch>
          <Route path='/:category?'>
            <FicheView />
          </Route>
        </Switch>
        <Pdf />
        {isPhone && (
          <CategoryOverview
            categories={expandedCategories}
            onAllClick={onAllClick}
            onFavoritesClick={onFavoritesClick}
            onCategoryClick={onCategoryClick}
          />
        )}
      </>
    </SolarApp>
  );
}

import React from 'react';
import { AppBar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import { Flex, menuButton, Toolbar, useWidth } from '@solar/solar-react';
import clsx from 'clsx';

import NavDrawer from './NavDrawer';

const WIDTH = 512;

const useStyles = makeStyles(theme => ({
  // Styles for mini variant Drawer:
  drawerPaper: {
    position: 'relative',
    width: WIDTH,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    zIndex: 'auto',
    borderRight: 'none',
    [theme.breakpoints.down('sm')]: {
      width: 350,
    },
  },
  drawerPhone: {
    position: 'fixed',
  },
  inner: {
    height: '100%',
  },
  drawerPaperClosed: {
    width: 72,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  drawerInner: {
    position: 'relative',
    height: '100vh !important',
    // Make the items inside not wrap when transitioning:
    width: WIDTH,
    [theme.breakpoints.down('sm')]: {
      width: 350,
    },
  },
  appBar: {
    // boxShadow: "none",
    // backgroundColor: theme.palette.background.paper,
  },
  menuButtonWrapper: {
    position: 'absolute',
    zIndex: theme.zIndex.appBar + 2,
    top: 0,
    left: 0,
    height: 64,
    width: 128,
    background: `linear-gradient(to right, rgba(${theme.palette.primary.main},0.8), rgba(${theme.palette.primary.main},0))`,
  },
  menuButtonWrapped: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    color: theme.palette.common.white,
  },
  menuButton: {
    ...menuButton(theme),
  },
  mobile: {
    zIndex: theme.zIndex.drawer,
  },
  navigation: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  navigationDocked: {
    width: 72,
  },
  topToolbar: {},
}));

export type NavDrawerProps = {
  open: boolean;
  onDrawerRequestClose: () => void;
  onDrawerRequestOpen: () => void;
  classes?: unknown;
};

const ResponsiveNavigationDrawer: React.FC<NavDrawerProps> = ({
  children,
  open,
  onDrawerRequestClose,
  onDrawerRequestOpen,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const width = useWidth();
  const isPhone = isWidthDown('xs', width);
  const isWide = isWidthUp('lg', width);
  return (
    <>
      {isPhone && !open && (
        <div className={classes.menuButtonWrapper}>
          <IconButton
            onClick={onDrawerRequestOpen}
            className={clsx(classes.menuButtonWrapped, classes.mobile)}
          >
            <MenuIcon />
          </IconButton>
        </div>
      )}
      <NavDrawer
        variant={isPhone ? 'temporary' : 'permanent'}
        open={isWide || open}
        onClose={onDrawerRequestClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            isPhone && classes.drawerPhone,
            !open && !isWide && classes.drawerPaperClosed,
          ),
        }}
        {...other}
      >
        <Flex fullColumn className={classes.drawerInner}>
          {!isWide && (
            <AppBar position='static' className={classes.appBar} elevation={4}>
              <Toolbar className={classes.topToolbar}>
                <IconButton
                  onClick={open ? onDrawerRequestClose : onDrawerRequestOpen}
                  className={classes.menuButton}
                >
                  {open ? <ChevronLeftIcon /> : <MenuIcon />}
                </IconButton>
              </Toolbar>
            </AppBar>
          )}
          <div
            className={clsx(
              classes.navigation,
              !open && !isWide && classes.navigationDocked,
            )}
          >
            {children}
          </div>
        </Flex>
      </NavDrawer>
    </>
  );
};

export default ResponsiveNavigationDrawer;

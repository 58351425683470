import { useEffect, useState } from 'react';

import useFetchHtml from './useFetchHtml';
import useLocalStorage from './useLocalStorage';

type CachedItem<T> = {
  storeDate: number;
  data: T;
};

const useCachedHtmlParse = <T extends unknown>({
  url,
  documentParse,
  cacheTime,
}: {
  url: string;
  documentParse: (document: Document) => T;
  cacheTime: number;
}) => {
  const [cachedData, setCachedData] = useLocalStorage<CachedItem<T>>(
    `cache/${url}`,
  );
  const [canFetch, setCanFetch] = useState(!cachedData);

  const { html, isLoading, error } = useFetchHtml(url, canFetch);

  useEffect(() => {
    if (cachedData) {
      if (cachedData.storeDate + cacheTime < Date.now() && !canFetch) {
        setCanFetch(true);
      }
    }
  }, [cachedData, cacheTime, canFetch]);

  useEffect(() => {
    if (html) {
      const parsedData = documentParse(html);

      const storeData = {
        storeDate: Date.now(),
        data: parsedData,
      };

      setCachedData(storeData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html, documentParse]);

  return { data: cachedData?.data, isLoading, error };
};

export default useCachedHtmlParse;

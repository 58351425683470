import React from 'react';
import { Page } from 'react-pdf';
import { Box, Card, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Fiche } from '../context/dataContext';
import { usePhone } from '../hooks';
import FicheCardContent from './FicheCardContent';

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345,
    overflow: 'scroll',
    // height: "100%",
    position: 'absolute',
    height: 'calc(100vh - 64px)',
    zIndex: 999999,
  },
  mobileRoot: {
    position: 'absolute',
    bottom: 0,
    width: '100vw',
    height: 'auto',
  },
  dialog: {
    maxWidth: 1920,
  },
  page: {
    margin: theme.spacing(1),
  },
}));

export interface PDFMenuProps {
  nbPages: number;
  activePage: number;
  fiche: Fiche;
  setActivePage: (arg: number) => void;
}

const RATIO = 210 / 297; // DIN

const PDFMenu: React.FC<PDFMenuProps> = ({
  nbPages,
  activePage,
  fiche,
  setActivePage,
}) => {
  const classes = useStyles();
  const isPhone = usePhone();

  const pages = Array(...Array(nbPages));

  const direction = isPhone ? 'row' : 'column';
  const width = isPhone ? 80 : 320;

  return (
    <Box className={clsx(classes.root, { [classes.mobileRoot]: isPhone })}>
      <Box
        width={(isPhone ? nbPages : 1) * width}
        display='flex'
        flexDirection={direction}
      >
        {!isPhone && (
          <FicheCardContent
            {...fiche}
            pdfUrl={fiche.pdfUrl}
            onOpenPdf={() => {}}
            active
          />
        )}
        {pages.map((_, p) => (
          <Card className={classes.page}>
            <CardActionArea onClick={() => setActivePage(p)}>
              <Page
                // eslint-disable-next-line react/no-array-index-key
                key={`page_${p}`}
                pageNumber={p + 1}
                width={width}
                scale={1}
                height={width * RATIO}
                // onClick={() => setActivePage((activePage + 1) % nbPages)}
              />
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default PDFMenu;

import { FC } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import OfflineIcon from '@material-ui/icons/WifiOff';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(30),
    fontSize: '10em',
    color: theme.palette.primary.light,
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
}));

const OfflineScreen: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography variant='h3' className={classes.title}>
          Er kon geen verbinding gemaakt worden
        </Typography>
        <OfflineIcon className={classes.icon} />
      </Box>
    </Box>
  );
};

export default OfflineScreen;

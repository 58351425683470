import { Context, createContext, FC, useContext } from 'react';

import useLocalStorage from '../hooks/useLocalStorage';

type LikesRepo = {
  [ID: string]: boolean;
};

export type LikesContextType = {
  likes: LikesRepo;
  isLiked: (ID: string) => boolean;
};

export type LikesDispatchContextType = {
  like: (ID: string, value: boolean) => void;
};

export const LikesContext: Context<LikesContextType | undefined> =
  createContext<LikesContextType | undefined>(undefined);

export const LikesDispatchContext: Context<
  LikesDispatchContextType | undefined
> = createContext<LikesDispatchContextType | undefined>(undefined);

const LikesProvider: FC = ({ children }) => {
  const [likes = {}, setLikes] = useLocalStorage<LikesRepo>('dm-likes', {});
  const like = (ID: string, value: boolean) => {
    setLikes({ ...likes, [ID]: value });
  };
  const isLiked = (ID: string) => !!likes[ID];

  return (
    <LikesContext.Provider value={{ likes, isLiked }}>
      <LikesDispatchContext.Provider
        value={{
          like,
        }}
      >
        {children}
      </LikesDispatchContext.Provider>
    </LikesContext.Provider>
  );
};

function useLikesState() {
  const context = useContext(LikesContext);
  if (context === undefined) {
    throw new Error('useLikesState must be used within a LikesProvider');
  }
  return context;
}

function useLikesDispatch() {
  const context = useContext(LikesDispatchContext);
  if (context === undefined) {
    throw new Error('useLikesDispatch must be used within a LikesProvider');
  }
  return context;
}

function useLikes(): [LikesContextType, LikesDispatchContextType] {
  return [useLikesState(), useLikesDispatch()];
}

export { LikesProvider, useLikesState, useLikesDispatch, useLikes };

import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useData } from '../../context/dataContext';
import PDFDialog from './PDFDialog';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Pdf: FC = () => {
  const location = useLocation();
  const query = useQuery();
  const history = useHistory();
  const pdfId = query.get('pdf');
  const { allFiches } = useData();

  if (!pdfId) {
    return null;
  }

  const fiche = allFiches?.find(
    value => value.pdfUrl && encodeURI(value.id) === pdfId,
  );

  if (!fiche?.pdfUrl) {
    return null;
  }

  return (
    <PDFDialog
      fiche={fiche}
      open={!!fiche?.pdfUrl}
      onClose={() => {
        query.delete('pdf');
        const q = query.toString();
        history.push(location.pathname + q ? `?${q}` : '');
      }}
    />
  );
};

export default Pdf;

import React from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(() => ({
  root: {},
  textField: {
    backgroundColor: 'white',
    fontSize: '2em',
    border: `solid 2px`,
  },
  input: {
    fontSize: '1.5em',
    fontWeight: 800,
  },
}));

export interface SearchFieldProps {
  onChange: (arg0: string) => void;
  value: string;
  onClear: () => void;
}

const SearchField: React.FC<SearchFieldProps> = (props: SearchFieldProps) => {
  const classes = useStyles();
  const { value, onClear, onChange } = props;

  return (
    <TextField
      variant='outlined'
      size='small'
      fullWidth
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.value)
      }
      classes={{ root: classes.textField }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
        classes: { input: classes.input },
        ...(value.length > 0
          ? {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => onClear()}
                    edge='end'
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}),
      }}
    />
  );
};

export default SearchField;

import React, { useState } from 'react';
import { Card, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import FicheCardContent, { FicheCardProps } from './FicheCardContent';

const useStyles = makeStyles(() => ({
  root: ({
    interactive,
    hovered,
  }: {
    interactive: boolean;
    hovered: boolean;
  }) => ({
    transition: 'transform .2s ease-in-out',
    transform: `scale(${interactive && hovered ? 1.05 : 1})`,
    height: '100%',
  }),
  actionArea: {
    height: '100%',
  },
}));

const FicheCard: React.FC<FicheCardProps> = (props: FicheCardProps) => {
  const { pdfUrl, onOpenPdf, id } = props;
  const [hovered, setHovered] = useState(false);

  const interactive = !!pdfUrl && !!onOpenPdf;

  const classes = useStyles({ interactive, hovered });

  return (
    <Card elevation={interactive && hovered ? 2 : 0} className={classes.root}>
      {interactive ? (
        <CardActionArea
          onClick={() => onOpenPdf && onOpenPdf(id)}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={classes.actionArea}
        >
          <FicheCardContent {...props} />
        </CardActionArea>
      ) : (
        <FicheCardContent {...props} />
      )}
    </Card>
  );
};

export default FicheCard;

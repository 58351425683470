import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { useCategory } from '../../context/categoryContext';
import { useData } from '../../context/dataContext';
import { useLikesState } from '../../context/likesContext';
import FicheCardSearch from './FicheCardSearch';

interface CategoryMatch {
  category: string;
}

const FicheView: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const { params } = useRouteMatch<CategoryMatch>();
  const { category: categoryId } = params;
  const { categories, allFiches } = useData();

  const { setCategory } = useCategory();

  const { likes } = useLikesState();
  const favIds = Object.keys(likes).filter(k => likes[k]);

  useEffect(() => {
    setCategory(categoryId);
  }, [categoryId, setCategory]);

  const filteredFiches = useMemo(() => {
    if (categoryId && categories) {
      return categoryId === 'favorites'
        ? allFiches?.filter(fiche => favIds.includes(fiche.id))
        : categories[categoryId].fiches;
    }
    return allFiches;
  }, [categoryId, categories, allFiches, favIds]);

  if (!filteredFiches || !categories) {
    return null;
  }

  return (
    <FicheCardSearch
      fiches={filteredFiches}
      category={categoryId ? categories[categoryId] : undefined}
      onOpenPdf={id => {
        const searchParam = new URLSearchParams(location.search);
        searchParam.set('pdf', id);
        const q = searchParam.toString();
        history.push(location.pathname + q ? `?${q}` : '');
      }}
      onClickClose={() => {
        history.push('/');
      }}
      search={search}
      onSearch={setSearch}
    />
  );
};

export default FicheView;

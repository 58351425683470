import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Logo = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 512 512'>
    <g transform='translate(0,512) scale(0.100000,-0.100000)' stroke='none'>
      <path
        d='M0 2575 l0 -2545 2545 0 2545 0 0 2545 0 2545 -2545 0 -2545 0 0
-2545z m2058 3 l-3 -923 -177 -3 -178 -2 0 40 c0 22 -2 40 -5 40 -3 0 -22 -8
-43 -19 -20 -10 -67 -26 -103 -35 -151 -37 -593 -29 -749 15 -198 55 -315 176
-356 367 -20 95 -19 491 1 587 30 140 112 253 225 308 125 61 187 70 520 74
311 5 310 5 478 -34 l22 -5 0 256 0 256 185 0 185 0 -2 -922z m432 384 l0 -29
56 19 c82 28 178 37 374 38 235 0 326 -12 415 -54 39 -19 83 -45 100 -59 l29
-24 56 37 c79 53 157 79 279 92 138 14 424 5 515 -17 199 -47 326 -160 371
-330 12 -49 15 -138 15 -522 l0 -463 -185 0 -184 0 -3 453 c-3 499 -1 478 -68
516 -52 29 -206 45 -353 36 -189 -10 -252 -40 -276 -130 -7 -26 -11 -188 -11
-457 l0 -418 -184 0 -185 0 -3 443 c-3 498 -3 498 -76 533 -52 25 -196 37
-357 30 -194 -8 -254 -28 -293 -99 -15 -29 -17 -77 -20 -469 l-3 -438 -185 0
-184 0 0 670 0 670 180 0 180 0 0 -28z'
        fill='currentColor'
      />
      <path
        d='M943 2680 c-61 -13 -100 -36 -120 -73 -15 -27 -18 -64 -21 -252 -3
-213 -2 -221 20 -264 16 -31 36 -51 63 -65 38 -20 57 -21 350 -21 l310 0 45
25 c33 19 51 38 70 75 24 48 25 58 25 235 -1 117 -5 200 -13 227 -14 47 -55
87 -110 104 -45 15 -556 22 -619 9z'
        fill='currentColor'
      />
    </g>
  </SvgIcon>
);

Logo.muiName = 'LogoIcon';

export default Logo;

import { FC } from 'react';

import CategoryLabel from './CategoryLabel';
import MenuListItem from './MenuListItem';

export type CategoryListItemProps = {
  categoryLabel: string;
  title: string;
  description?: string;
  isActive?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  className?: string;
};

const CategoryListItem: FC<CategoryListItemProps> = ({
  categoryLabel,
  ...other
}) => (
  <MenuListItem icon={<CategoryLabel label={categoryLabel} />} {...other} />
);

export default CategoryListItem;

import { Theme, ThemeOptions } from '@material-ui/core/styles';

const slider = (theme: Theme): ThemeOptions['overrides'] => ({
  MuiSlider: {
    root: {
      color: theme.palette.primary.light,
      height: 8,
      padding: '10px 0',
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      // left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
      backgroundColor: '#EAF5F1', // theme.palette.background.default,
    },
    mark: {
      display: 'none',
    },
  },
});

export default slider;

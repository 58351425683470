import { ThemeOptions } from '@material-ui/core/styles';

const progress = (): ThemeOptions['overrides'] => ({
  MuiLinearProgress: {
    root: {
      height: 8,
      borderRadius: 9999,
    },
  },
});

export default progress;

import { createMuiTheme, Theme } from '@material-ui/core/styles';

import button from './button';
import progress from './progress';
import slider from './slider';
import textField from './textField';
import tooltip from './tooltip';

const overrides = (theme: Theme) =>
  createMuiTheme(theme, {
    overrides: {
      ...button(),
      ...tooltip(),
      ...textField(),
      ...slider(theme),
      ...progress(),
    },
  });

export default overrides;

export const increments = {
  typography: 4,
  mobile: 56,
  tablet: 64,
};

export const keylines = {
  mobileContent: 72,
  tabletContent: 80,
};

export const widths = {
  creationDialog: increments.tablet * 7,
  tabletActionBar: increments.tablet * 5,
  drawer: increments.tablet * 7,
};

export const heights = {
  toolbarTopMobile: increments.mobile,
  toolbarBottomMobile: keylines.mobileContent,
  toolbarTop: increments.tablet,
  toolbarBottom: keylines.tabletContent,
};

const theme = {
  spacing: 8,
  increments,
  keylines,
  widths,
  heights,
  shape: {
    borderRadius: 0,
  },
  // shadows: [
  //   "none",
  //   "0 16px 40px -12.125px rgba(0,0,0,0.3)",
  //   "0 16px 40px -12.125px rgba(0,0,0,0.3)",
  // ],
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      //   disableRipple: true, // No more ripple, on the whole application 💣!
    },
    MuiButton: {
      // disableElevation: true,
    },
    MuiCard: {
      // elevation: 1,
    },
    MuiTooltip: {
      // elevation: 1,
    },
    MuiSlider: {
      valueLabelDisplay: 'off',
    },
  },
};

export default theme;

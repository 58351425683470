import { isWidthDown, makeStyles } from '@material-ui/core';
import { useWidth } from '@solar/solar-react';

import { Fiche } from '../context/dataContext';
import CardList from '../solar/CardList';
import FicheCard from './FicheCard';
import { Size } from './FicheCardContent';

const useStyles = makeStyles(theme => ({
  cardGrid: {
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
}));

export type FicheCardListProps = {
  fiches: Fiche[];
  onOpenPdf: (arg: string) => void;
};

const FicheCardList: React.FC<FicheCardListProps> = ({ fiches, onOpenPdf }) => {
  const classes = useStyles();
  const width = useWidth();
  const isPhone = isWidthDown('sm', width);

  return (
    <CardList
      items={fiches}
      classes={{ root: classes.cardGrid }}
      upMap={{
        0: { cols: 1 },
        700: { cols: 2 },
        1082: { cols: 3 },
        1400: { cols: 4 },
      }}
    >
      {props => (
        <FicheCard
          {...props}
          key={props.id}
          onOpenPdf={onOpenPdf}
          size={isPhone ? Size.Small : Size.Large}
        />
      )}
    </CardList>
  );
};

export default FicheCardList;

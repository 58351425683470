import React from 'react';
import { Drawer, DrawerProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    width: `calc(100vw - ${theme.spacing(8)}px)`,
  },
  inner: {
    width: 'inherit',
  },
  [theme.breakpoints.up('sm')]: {
    paper: {
      width: theme.spacing(8) * 5,
    },
  },
}));

export type NavigationDrawerProps = {
  classes: unknown;
};

const NavigationDrawer: React.FC<NavigationDrawerProps & DrawerProps> = ({
  children,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Drawer
      classes={{
        paper: classes.paper,
      }}
      {...other}
    >
      <div className={classes.inner}>{children}</div>
    </Drawer>
  );
};

export default NavigationDrawer;

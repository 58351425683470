import React, { Ref, useEffect, useMemo, useState } from 'react';
import Measure from 'react-measure';
import { Document, Page } from 'react-pdf';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Fiche } from '../context/dataContext';
import Loader from '../screens/Loader/Loader';
import OfflineScreen from '../screens/Offline/OfflineScreen';
import PDFMenu from './PDFMenu';

const useStyles = makeStyles(() => {
  return {
    root: {
      width: '100%',
      height: '100%',
      // maxWidth: 345,
    },
    pdfPageWrapper: {
      marginLeft: 'auto',
      marginRight: 'auto',
      '& > *': { overflow: 'visible !important' },
    },
    document: {
      height: '100%',
      '& .react-pdf__message': {
        height: '100%',
      },
    },
  };
});

export interface PDFViewerProps {
  pdfSrc?: string;
  fiche: Fiche;
  setIsError: (isError: boolean) => void;
}
const RATIO = 210 / 297; // DIN
const MAX_WIDTH = 1200;

type Dimension = {
  width: number;
  height: number;
};

const PDFViewer: React.FC<PDFViewerProps> = ({ fiche, pdfSrc, setIsError }) => {
  const classes = useStyles();
  const [bounds, setBounds] = useState<Dimension>({ width: 1, height: 1 });
  const [nbPages, setNbPages] = useState(0);
  const [activePage, setActivePage] = useState(0);

  const maxed = (w: number) => Math.min(MAX_WIDTH, w);
  const width = useMemo(() => {
    if (bounds.width / bounds.height < RATIO) {
      return maxed(bounds.width);
    }
    return maxed(bounds.height * RATIO);
  }, [bounds]);

  // const isPhone = usePhone();
  useEffect(() => {
    setNbPages(0);
    setActivePage(0);
  }, [pdfSrc]);

  if (!pdfSrc) {
    return null;
  }

  return (
    <Measure
      bounds
      onResize={({ bounds: resizeBounds }) =>
        setBounds({
          width: resizeBounds?.width ?? 1,
          height: resizeBounds?.height ?? 1,
        })
      }
    >
      {({ measureRef }: { measureRef: Ref<HTMLDivElement> }) => (
        <div ref={measureRef} className={classes.root}>
          <Document
            file={pdfSrc}
            onLoadSuccess={data => {
              // eslint-disable-next-line no-underscore-dangle
              setNbPages(data._pdfInfo.numPages);
              setIsError(false);
            }}
            onLoadError={() => setIsError(true)}
            options={{}}
            className={classes.document}
            loading={<Loader />}
            error={<OfflineScreen />}
          >
            <Box display='flex' p={0}>
              <PDFMenu
                fiche={fiche}
                activePage={activePage}
                nbPages={nbPages}
                setActivePage={setActivePage}
              />

              <Box
                className={classes.pdfPageWrapper}
                style={{
                  width,
                }}
              >
                {nbPages && nbPages > 0 ? (
                  <TransformWrapper>
                    <TransformComponent>
                      <Page
                        key={`page_${activePage}`}
                        pageNumber={activePage + 1}
                        width={width}
                        scale={1}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                ) : null}
              </Box>
            </Box>
          </Document>
        </div>
      )}
    </Measure>
  );
};

export default PDFViewer;

import React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LikedIcon from '@material-ui/icons/Favorite';
import NotLikedIcon from '@material-ui/icons/FavoriteBorder';
import clsx from 'clsx';

import { useLikes } from '../context/likesContext';
import CategoryLabel from './CategoryLabel';

export enum Size {
  Small = 'small',
  Large = 'large',
}

interface StyleProps {
  interactive: boolean;
  active: boolean;
  size: Size;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: ({ interactive, active, size }) => ({
    color: theme.palette.primary.main,
    minHeight: size === Size.Large ? 400 : 160,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    ...(!interactive
      ? {
          backgroundColor: theme.palette.primary.light,
          border: 'none',
        }
      : {
          border: `solid ${theme.spacing(1)}px ${theme.palette.primary.main}`,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
          },
        }),
    ...(active
      ? {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        }
      : {}),
    padding: theme.spacing(2),
  }),
  title: ({ interactive, size }) => ({
    wordWrap: 'break-word',
    fontSize: size === Size.Large ? '2.2rem' : '1.8rem',
    wordBreak: 'break-word',
    lineHeight: '1em',
    flexGrow: 1,
    fontWeight: 800,
    color: interactive ? 'inherit' : 'white',
    textTransform: 'capitalize',
  }),
  interactive: {},
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  likeButton: {
    height: 'fit-content',
    color: 'inherit',
  },
}));

export interface FicheCardProps {
  id: string;
  title: string;
  categoryLabel: string;
  categoryTitle: string;
  lastUpdatedAt: string;
  pdfUrl?: string;
  onOpenPdf?: (url: string) => void;
  active?: boolean;
  size?: Size;
}

const FicheCardContent: React.FC<FicheCardProps> = ({
  id,
  title,
  categoryLabel,
  categoryTitle,
  pdfUrl,
  lastUpdatedAt,
  onOpenPdf,
  active,
  size = Size.Large,
}) => {
  const interactive = !!(pdfUrl && onOpenPdf);

  const classes = useStyles({ interactive, active: !!active, size });

  const [{ isLiked }, { like }] = useLikes();

  return (
    <Box className={clsx(classes.root, { [classes.interactive]: interactive })}>
      <Box className={classes.top}>
        <Typography variant='h1' gutterBottom className={classes.title}>
          {title}
        </Typography>
        {!!id && (
          <IconButton
            className={classes.likeButton}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              like(id, !isLiked(id));
            }}
          >
            {isLiked(id) ? <LikedIcon /> : <NotLikedIcon />}
          </IconButton>
        )}
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='flex-end'>
        <Box>
          <Typography variant='caption'>{lastUpdatedAt}</Typography>
          <Typography variant='h5'>{categoryTitle}</Typography>
        </Box>
        <CategoryLabel label={categoryLabel} />
      </Box>
    </Box>
  );
};

export default FicheCardContent;

import React, {
  Context,
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from 'react';

export type UIStateContextType = {
  view: string;
  drawerOpen: boolean;
};

export type UIDispatchContextType = {
  setView: (arg0: string) => void;
  setDrawerOpen: (arg0: boolean) => void;
};

export const UIStateContext: Context<UIStateContextType | undefined> =
  createContext<UIStateContextType | undefined>(undefined);

export const UIDispatchContext: Context<UIDispatchContextType | undefined> =
  createContext<UIDispatchContextType | undefined>(undefined);

const UIProvider: FunctionComponent = ({ children }) => {
  const [view, setView] = useState<string>('card');
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  return (
    <UIStateContext.Provider value={{ view, drawerOpen }}>
      <UIDispatchContext.Provider
        value={{
          setView,
          setDrawerOpen,
        }}
      >
        {children}
      </UIDispatchContext.Provider>
    </UIStateContext.Provider>
  );
};

function useUIState() {
  const context = useContext(UIStateContext);
  if (context === undefined) {
    throw new Error('useUIState must be used within a UIProvider');
  }
  return context;
}

function useUIDispatch() {
  const context = useContext(UIDispatchContext);
  if (context === undefined) {
    throw new Error('useUIDispatch must be used within a UIProvider');
  }
  return context;
}

function useUI(): [UIStateContextType, UIDispatchContextType] {
  return [useUIState(), useUIDispatch()];
}

export { UIProvider, useUIState, useUIDispatch, useUI };

import { FC, useState } from 'react';

import { Category } from '../../context/dataContext';
import { CategoryOverviewDialog } from './CategoryOverviewDialog';

export interface CategoryOverviewProps {
  categories: Category[];
  onAllClick: () => void;
  onFavoritesClick: () => void;
  onCategoryClick: (category: string) => void;
}

const CategoryOverview: FC<CategoryOverviewProps> = ({
  categories,
  onAllClick,
  onFavoritesClick,
  onCategoryClick,
}) => {
  const isRoot = window.location.pathname === '/';

  const [isOpen, setIsOpen] = useState(isRoot);

  const onAllClickClose = () => {
    onAllClick();
    setIsOpen(false);
  };

  const onFavoritesClickClose = () => {
    onFavoritesClick();
    setIsOpen(false);
  };

  const onCategoryClickClose = (category: string) => {
    onCategoryClick(category);
    setIsOpen(false);
  };

  return (
    <CategoryOverviewDialog
      isOpen={isOpen}
      categories={categories}
      onAllClick={onAllClickClose}
      onFavoritesClick={onFavoritesClickClose}
      onCategoryClick={onCategoryClickClose}
    />
  );
};

export default CategoryOverview;

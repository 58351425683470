import {
  Context,
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export type CategoryContextType = {
  category: string | undefined;
  setCategory: Dispatch<SetStateAction<string | undefined>>;
};

export const CategoryContext: Context<CategoryContextType | undefined> =
  createContext<CategoryContextType | undefined>(undefined);

export const CategoryProvider: FC = ({ children }) => {
  const [category, setCategory] = useState<string>();

  return (
    <CategoryContext.Provider value={{ category, setCategory }}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = () => {
  const context = useContext(CategoryContext);
  if (context === undefined) {
    throw new Error('useCategory must be used within a CategoryProvider');
  }

  return context;
};

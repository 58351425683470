import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import gidole from './fonts/Gidole';
import globalTheme from './general';
import overrides from './overrides';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#012169',
        light: '#CFEAFF',
      },
      secondary: {
        main: '#0099a8',
      },
      // accent: "#FF9900", //orange
      text: {
        primary: '#212529',
        secondary: 'rgba(33, 37, 41, 0.8)',
      },
      background: {
        paper: '#fff',
        default: '#F4FAF8',
      },
    },
    typography: {
      fontSize: 16,
      fontFamily: 'Gidole, Arial',
      h1: {
        fontWeight: 400,
        // textTransform: "uppercase"
      },
      h2: {
        fontWeight: 400,
      },
      h3: {
        fontWeight: 400,
      },
      h5: {
        fontSize: 22,
        fontWeight: 400,
      },
      subtitle1: {},
      body1: {
        fontWeight: 400,
      },
      button: {
        // fontStyle: "italic"
        fontWeight: 500,
        textTransform: 'none',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': gidole,
          // a: {
          //   color: palette.primary.main,
          //   textDecoration: 'none',
          //   '&:hover': {
          //     textDecoration: 'underline',
          //   },
          // },
          // ".MuiFilledInput-root.Mui-focused": {
          //   backgroundColor: "rgba(255, 255, 255, 0.95)"
          // }
        },
      },
    },
  },
  globalTheme,
);

export default responsiveFontSizes(overrides(theme));

import { FC } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

import LogoFull from '../icons/LogoFull';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    margin: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  logo: {
    fontSize: '8em',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
}));

export const LogoHeader: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <LogoFull className={classes.logo} />
      <Typography variant='h3'>Steekkaarten</Typography>
    </Box>
  );
};

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid ${theme.spacing(1)}px ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(0),
  },
  label: {
    wordWrap: 'break-word',
    fontSize: '1.5rem',
    lineHeight: '1em',
    flexGrow: 1,
    fontWeight: 800,
    color: 'inherit',
  },
}));

export interface CategoryLabelProps {
  label: string;
}

const CategoryLabel: React.FC<CategoryLabelProps> = (
  props: CategoryLabelProps,
) => {
  const classes = useStyles();
  const { label } = props;

  return (
    <Box className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
};

export default CategoryLabel;

import { useEffect, useState } from 'react';

const useFetchHtml = (url?: string, canFetch = true) => {
  const [isLoading, setIsLoading] = useState(canFetch);
  const [error, setError] = useState<unknown>();
  const [html, setHtml] = useState<Document>();

  const onCatch = (reason: unknown) => {
    setError(reason);
    setIsLoading(false);
  };

  useEffect(() => {
    if (url && canFetch) {
      setIsLoading(true);
      setError(undefined);
      fetch(url)
        .then(value => {
          value
            ?.text()
            .then(htmlString => {
              const htmlDocument = new DOMParser().parseFromString(
                htmlString,
                'text/html',
              );
              setHtml(htmlDocument);
              setIsLoading(false);
            })
            .catch(onCatch);
        })
        .catch(onCatch);
    }
  }, [url, canFetch]);

  return { html, isLoading, error };
};

export default useFetchHtml;

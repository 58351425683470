import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const LogoFull = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 1470.8533 1470.8533'>
    <g
      xmlns='http://www.w3.org/2000/svg'
      id='g30'
      transform='matrix(.13333333 0 0 -.13333333 0 1470.8533)'
    >
      <path
        id='path2'
        fill='#1f357a'
        d='m0 11031.4h11031.4v-11031.4h-11031.4z'
      />
      <g id='g28' fill='#fff'>
        <path
          id='path4'
          d='m2574.11 3136.06h298.26v1581.71h-341.19l-494.85-680.13-494.85 680.13h-343.46v-1581.71h298.27v1150.13l503.89-689.18h58.75l515.18 686.91z'
        />
        <path
          id='path6'
          d='m5095.4 4720.03c-194.32 0-427.06 0-621.38 0v-1581.71h621.38c553.6 0 797.63 404.46 790.85 804.41-6.77 390.91-248.55 777.3-790.85 777.3zm-325.38-282.45h325.38c343.46 0 488.07-246.29 494.85-492.59 6.78-257.59-135.57-519.7-494.85-519.7h-325.38z'
        />
        <path
          id='path8'
          d='m7864.31 3542.78c-103.94-103.93-259.85-160.43-397.69-160.43-390.91 0-542.3 273.41-544.55 535.53-2.27 264.36 162.68 549.07 544.55 549.07 137.84 0 277.93-47.45 381.87-149.13l198.84 192.06c-162.69 160.43-366.05 241.78-580.71 241.78-571.67 0-840.56-420.28-838.3-833.78 2.25-411.25 250.81-815.71 838.3-815.71 228.22 0 438.36 74.57 601.05 235z'
        />
        <path id='path10' d='m6402.13 3138.32v1581.71h-296v-1581.71z' />
        <path
          id='path12'
          d='m1835.01 7924.49c-202.91 0-445.92 0-648.82 0v-1651.55h648.82c578.04 0 832.86 422.33 825.78 839.93-7.08 408.17-259.54 811.62-825.78 811.62zm-339.75-294.92h339.75c358.62 0 509.62-257.17 516.7-514.34 7.08-268.97-141.56-542.65-516.7-542.65h-339.75z'
        />
        <path
          id='path14'
          d='m3642.29 7957.53c-570.97 0-849.37-436.49-849.37-870.61s268.96-851.73 849.37-851.73c580.4 0 842.29 427.04 847.01 854.09 4.71 431.76-278.41 868.25-847.01 868.25zm0-283.13c389.29 0 545-311.43 540.29-589.84-4.72-271.32-151-563.89-540.29-563.89-389.3 0-535.58 290.2-542.66 561.53-7.08 278.4 153.36 592.2 542.66 592.2'
        />
        <path
          id='path16'
          d='m6157.37 6270.58h311.43v1651.56h-356.26l-516.71-710.17-516.69 710.17h-358.62v-1651.56h311.43v1200.92l526.14-719.61h61.34l537.94 717.25z'
        />
        <path
          id='path18'
          d='m7900.94 7922.14v-946.11c0-283.12-146.28-455.36-403.45-455.36s-427.05 158.08-427.05 455.36v946.11h-309.07v-946.11c0-497.83 337.38-738.48 733.76-738.48 415.25 0 714.88 250.09 714.88 738.48v946.11z'
        />
        <path
          id='path20'
          d='m9781.35 7653.17c-125.04 254.81-391.65 327.95-648.82 327.95-304.35-2.36-639.39-141.56-639.39-481.31 0-370.42 311.44-460.08 648.83-500.19 219.42-23.59 382.21-87.29 382.21-243.01 0-179.31-184.03-247.73-379.85-247.73-200.55 0-391.66 80.22-464.79 261.89l-259.54-134.49c122.69-301.99 382.22-405.81 719.6-405.81 368.07 0 693.66 158.08 693.66 526.14 0 394.01-320.87 483.67-665.34 526.14-198.19 23.59-368.06 63.7-368.06 207.62 0 122.69 110.89 219.42 342.11 219.42 179.31 0 335.03-89.65 391.66-184.03z'
        />
        <path
          id='path22'
          d='m3080.07 4066.76c0-166.29-.38-797.07-.38-928.69h1184.03v293.75h-888.02v357.01h856.38v277.93'
        />
        <path id='path24' d='m3080.07 4430.55h1183.65v289.22h-1184.03z' />
        <path
          id='path26'
          d='m8937.38 4418.21 548.08-1279.64h325.37l-709.51 1581.71h-325.37l-709.51-1581.71h323.13z'
        />
      </g>
    </g>
  </SvgIcon>
);

LogoFull.muiName = 'LogoFullIcon';

export default LogoFull;

import React, { Fragment } from 'react';
import { Divider, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LikeIcon from '@material-ui/icons/Favorite';

import { useCategory } from '../context/categoryContext';
import { Category } from '../context/dataContext';
import { useLikesState } from '../context/likesContext';
import Logo from '../icons/LogoIcon';
import CategoryListItem from './CategoryListItem';
import MenuListItem from './MenuListItem';

const useStyles = makeStyles(theme => ({
  root: ({ widthFull }: { widthFull: boolean | undefined }) => ({
    paddingTop: 0,
    width: '100vw',
    maxWidth: widthFull ? '100%' : 512,
    [theme.breakpoints.down('sm')]: {
      maxWidth: widthFull ? '100%' : 350,
    },
  }),
  icon: {
    color: theme.palette.primary.main,
    fontSize: '2.8em',
  },
  mainItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export interface CategoryMenuProps {
  categories: Category[];
  onAllClick: () => void;
  onFavoritesClick: () => void;
  onCategoryClick: (category: string) => void;
  widthFull?: boolean;
  showActiveCategory?: boolean;
}

const CategoryMenu: React.FC<CategoryMenuProps> = ({
  categories,
  onCategoryClick,
  onAllClick,
  onFavoritesClick,
  widthFull,
  showActiveCategory,
}) => {
  const classes = useStyles({ widthFull });
  const { likes } = useLikesState();
  const { category: activeCategoryId } = useCategory();
  const numberOfLikes = Object.values(likes).filter(l => l).length;

  return (
    <List className={classes.root}>
      <MenuListItem
        icon={<Logo className={classes.icon} />}
        title='Alle steekkaarten'
        isActive={showActiveCategory && activeCategoryId === undefined}
        onClick={onAllClick}
        className={classes.mainItem}
      />
      <Divider />
      <MenuListItem
        icon={<LikeIcon className={classes.icon} />}
        title='favorieten'
        isActive={showActiveCategory && activeCategoryId === 'favorites'}
        description={`${numberOfLikes} kaarten`}
        onClick={onFavoritesClick}
      />
      {categories.map(({ categoryId, title, description, label }) => (
        <Fragment key={categoryId}>
          <Divider />
          <CategoryListItem
            categoryLabel={label}
            title={title}
            isActive={showActiveCategory && activeCategoryId === categoryId}
            description={description}
            onClick={() => onCategoryClick(categoryId)}
          />
        </Fragment>
      ))}
    </List>
  );
};

export default CategoryMenu;

import { ThemeOptions } from '@material-ui/core/styles';

const link = (): ThemeOptions['overrides'] => ({
  MuiTooltip: {
    tooltip: {
      borderRadius: 12,
      padding: 16,
      fontSize: '1em',
      boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
    },
  },
});

export default link;

import { ThemeOptions } from '@material-ui/core/styles';

const button = (): ThemeOptions['overrides'] => ({
  MuiButton: {
    root: {
      borderRadius: 0,
    },
  },
});

export default button;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  CssBaseline,
  MuiThemeProvider as ThemeProvider,
} from '@material-ui/core';

import { CategoryProvider } from './context/categoryContext';
import { DataProvider } from './context/dataContext';
import { LikesProvider } from './context/likesContext';
import { UIProvider } from './context/uiContext';
import theme from './theme/domus-medica';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DataProvider>
        <UIProvider>
          <LikesProvider>
            <CategoryProvider>
              <Router>
                <App />
              </Router>
            </CategoryProvider>
          </LikesProvider>
        </UIProvider>
      </DataProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

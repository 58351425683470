import React, { Fragment, useMemo } from 'react';
import { Divider, List, makeStyles } from '@material-ui/core';
import { EmptyState } from '@solar/solar-react';
import elasticlunr from 'elasticlunr';

import CategoryListItem from '../../components/CategoryListItem';
import FicheCardList from '../../components/FicheCardList';
import FicheListItem from '../../components/FicheListItem';
import { LogoHeader } from '../../components/LogoHeader';
import SearchField from '../../components/SearchField';
import { Category, Fiche } from '../../context/dataContext';
import { useUIState } from '../../context/uiContext';
import { usePhone } from '../../hooks';

const useStyles = makeStyles(theme => ({
  root: {},
  searchWrapper: {
    maxWidth: 500,
    width: '100%',
    display: 'inline-block',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      maxWidth: 800,
    },
  },
  categoryWrapper: {
    maxWidth: 500,
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 800,
    },
  },
}));

export interface FicheCardSearchProps {
  fiches: Fiche[];
  category?: Category;
  onOpenPdf: (arg: string) => void;
  onSearch: (arg: string) => void;
  search: string;
  onClickClose: () => void;
}

interface SearchType {
  [ID: string]: Fiche;
}

const FicheCardSearch: React.FC<FicheCardSearchProps> = (
  props: FicheCardSearchProps,
) => {
  const classes = useStyles();
  const isPhone = usePhone();
  const { view } = useUIState();

  const { fiches, search, onSearch, category, onClickClose, ...other } = props;

  const ficheMap: SearchType = useMemo(
    () => fiches.reduce((memo, fiche) => ({ ...memo, [fiche.id]: fiche }), {}),
    [fiches],
  );

  const index = useMemo(() => {
    const elasticlunrIndex = elasticlunr<Fiche>(item => {
      item.addField('pdfUrl');
      item.addField('title');
      item.addField('categoryTitle');
      item.addField('description');
      item.setRef('id');
      item.saveDocument(false);
    });
    fiches.forEach(p => {
      elasticlunrIndex.addDoc(p);
    });

    // return [memoizeWith(identity, searchTerm => fuse.search(searchTerm))];
    return elasticlunrIndex;
  }, [fiches]);

  const filteredFiches = useMemo(
    () =>
      search.length > 1
        ? index
            .search(search, {
              fields: {
                pdfUrl: { boost: 4 },
                title: { boost: 6 },
                description: { boost: 4 },
                categoryTitle: { boost: 2 },
              },
              expand: true,
              // bool: "OR",
            })
            .filter(({ score }) => score > 0.02)
            .map(({ ref }) => ficheMap[ref])
        : fiches,
    [fiches, search, index, ficheMap],
  );

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        {!isPhone && !category && <LogoHeader />}
        <div className={classes.searchWrapper}>
          <SearchField
            value={search}
            onChange={onSearch}
            onClear={() => onSearch('')}
          />
        </div>
      </div>
      {category && (
        <List style={{ width: '100%' }}>
          <CategoryListItem
            categoryLabel={category.label}
            title={category.title}
            onClose={() => onClickClose()}
            className={classes.categoryWrapper}
          />
        </List>
      )}
      {filteredFiches.length > 0 ? (
        <>
          {!isPhone || view === 'card' ? (
            <FicheCardList fiches={filteredFiches} {...other} />
          ) : (
            <List style={{ width: '100%' }}>
              {filteredFiches.map(fiche => (
                <Fragment key={fiche.id}>
                  <Divider />
                  <FicheListItem {...fiche} {...other} withLabel={false} />
                </Fragment>
              ))}
            </List>
          )}
        </>
      ) : (
        <EmptyState label='geen resultaten' />
      )}
    </>
  );
};

export default FicheCardSearch;

import { FC } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';

import { LogoHeader } from '../../components/LogoHeader';

export interface LoaderProps {
  hasLogo?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  spinner: {
    marginTop: theme.spacing(30),
    marginBottom: theme.spacing(30),
  },
}));

const Loader: FC<LoaderProps> = ({ hasLogo }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        {hasLogo && <LogoHeader />}
        <CircularProgress className={classes.spinner} size={50} />
      </Box>
    </Box>
  );
};

export default Loader;

import React from 'react';
import {
  Box,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LikedIcon from '@material-ui/icons/Favorite';
import NotLikedIcon from '@material-ui/icons/FavoriteBorder';
import clsx from 'clsx';

import { useLikes } from '../context/likesContext';
import CategoryLabel from './CategoryLabel';

interface StyleProps {
  interactive: boolean;
  active: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {},
  title: {},
  label: {
    width: 54,
  },
  interactive: {},
}));

export interface FicheListItemProps {
  id: string;
  title: string;
  categoryLabel: string;
  lastUpdatedAt: string;
  pdfUrl?: string;
  onOpenPdf?: (url: string) => void;
  active?: boolean;
  withLabel?: boolean;
}

const FicheListItem: React.FC<FicheListItemProps> = ({
  id,
  title,
  categoryLabel,
  pdfUrl,
  lastUpdatedAt,
  onOpenPdf,
  active,
  withLabel = true,
}) => {
  const interactive = !!(pdfUrl && onOpenPdf);
  const classes = useStyles({ interactive, active: !!active });

  const [{ isLiked }, { like }] = useLikes();

  return (
    <ListItem
      className={clsx(classes.root, { [classes.interactive]: interactive })}
      button={!!(pdfUrl && onOpenPdf) as true}
      onClick={() => onOpenPdf && onOpenPdf(id)}
      disabled={!pdfUrl}
    >
      {withLabel && (
        <Box className={classes.label}>
          <CategoryLabel label={categoryLabel} />
        </Box>
      )}
      <ListItemText primary={title} secondary={lastUpdatedAt} />
      {id && (
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => {
              like(id, !isLiked(id));
            }}
          >
            {isLiked(id) ? <LikedIcon /> : <NotLikedIcon />}
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default FicheListItem;

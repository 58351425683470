import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';

import { useUI } from '../context/uiContext';
import { usePhone } from '../hooks';
import ResponsiveDrawer from './ResponsiveDrawer';

type AppBarProps = {
  toolbarContent: React.ReactNode;
};

const AppBarWrapper: React.FC<AppBarProps> = ({ toolbarContent }) => {
  const isPhone = usePhone();
  if (!isPhone) return null;
  return (
    <AppBar
      position='static'
      style={{ height: 64, paddingTop: 4 }}
      elevation={4}
    >
      <Toolbar style={{ justifyContent: 'space-between', paddingLeft: 64 }}>
        {toolbarContent}
      </Toolbar>
    </AppBar>
  );
};

type AppProps = {
  drawerContent: React.ReactNode;
};

export const App: React.FC<AppProps & AppBarProps> = ({
  children,
  drawerContent,
  toolbarContent,
}) => {
  const [{ drawerOpen }, { setDrawerOpen }] = useUI();

  return (
    <>
      <div
        style={{
          display: 'flex',
          height: '100vh',
          width: '100%',
        }}
      >
        <ResponsiveDrawer
          open={drawerOpen}
          onDrawerRequestClose={() => setDrawerOpen(false)}
          onDrawerRequestOpen={() => setDrawerOpen(true)}
        >
          {drawerContent}
        </ResponsiveDrawer>

        <div
          style={{
            maxWidth: 1717,
            marginLeft: 'auto',
            marginRight: 'auto',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflowX: 'hidden',
          }}
        >
          <AppBarWrapper toolbarContent={toolbarContent} />
          <div style={{ flexGrow: 1, overflow: 'scroll', width: '100%' }}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

import { FC, ReactNode } from 'react';
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

export type MenuListItemProps = {
  icon: ReactNode;
  title: string;
  description?: string;
  isActive?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  className?: string;
};

const useStyles = makeStyles(theme => ({
  root: ({ isActive }: { isActive: boolean | undefined }) => ({
    alignItems: 'center',
    backgroundColor: isActive ? theme.palette.primary.light : 'transparent',
  }),
  inline: { display: 'inline' },
  icon: {
    marginTop: 0,
  },
  type: { paddingLeft: theme.spacing(1) },
}));

const MenuListItem: FC<MenuListItemProps> = ({
  icon,
  title,
  description,
  isActive,
  onClick,
  onClose,
  className,
}) => {
  const classes = useStyles({ isActive });

  return (
    <ListItem
      className={clsx(classes.root, className)}
      button={!!onClick as true}
      onClick={onClick}
      alignItems='flex-start'
      classes={{ container: className }}
    >
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{ noWrap: true }}
        {...(description ? { secondary: description } : {})}
      />
      {onClose && (
        <ListItemSecondaryAction>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default MenuListItem;
